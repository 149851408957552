import ApiService, { HTTPMethod } from "./api.core";

class LoginModel {
    token: string;
    // expiration: Date;
  
    constructor(json: any) {
      this.token = json.token;
      // this.expiration = new Date(json.expiration);
    }
}  

export class AuthApi {

    static async login(email, password, utm_campaign, utm_medium, utm_source, utm_term, utm_content ): Promise<ApiResponseModel> {
        let resp = await ApiService.fetch("auth/token", HTTPMethod.POST, {
          email: email,
          password: password,
          utm_campaign: utm_campaign,
          utm_medium: utm_medium,
          utm_source: utm_source,
          utm_term: utm_term,
          utm_content: utm_content
        });
        if (resp.ok) {
          resp.parsedData = new LoginModel(resp.data);
        }
        return resp;
    }

    static async deeplinkTokenEntrance(profile,token) {
      let resp = await ApiService.fetch(`auth/deeplinkToken?profile=${profile}&token=${token}`, HTTPMethod.GET)
      return resp
  }

    static async logout() {
        return await ApiService.fetch("auth/logout");
    }

    static async socialLogin(email, token, serviceName) {
      let resp = await ApiService.fetch(`auth/${serviceName}/token`, HTTPMethod.POST, {
        access_token: token
      })
      resp.parsedData = resp.data;
      return resp
    }

    static async register(email, password, utm_campaign, utm_medium, utm_source, utm_term, utm_content) {
      let resp = await ApiService.fetch("auth/register", HTTPMethod.POST, {
        email: email,
        password: password,
        utm_campaign: utm_campaign,
        utm_medium: utm_medium,
        utm_source: utm_source,
        utm_term: utm_term,
        utm_content: utm_content
      });
      resp.parsedData = resp.data;
      return resp
    }

    static async registerPasswordless(email, selections,  utm_campaign, utm_medium, utm_source, utm_term, utm_content) {
      let resp = await ApiService.fetch("auth/register-passwordless", HTTPMethod.POST, {
        email,
        selections,
        utm_campaign: utm_campaign,
        utm_medium: utm_medium,
        utm_source: utm_source,
        utm_term: utm_term,
        utm_content: utm_content
      });
      resp.parsedData = resp.data;
      return resp
    }

    static async sendForgotPasswordEmail(email) {
      return ApiService.fetch("auth/forgot_password", HTTPMethod.POST, {
        email: email
      })
    }

    static async verifyActivationToken(activationToken, userId) {
      let resp = await ApiService.fetch("auth/verify-activation-token", HTTPMethod.POST, {
        token: activationToken,
        user_id: userId
      });
      resp.parsedData = resp.data;
      return resp
    }

}