import React, { Suspense, lazy } from 'react';
import './styles/styles.scss'
import './styles/margins.scss'
import './styles/colorstyles.scss'
import { FirebaseService, FirebaseConfigKey } from './services/FirebaseService';
import SessionContext from './context/SessionContext';
import BuyingInsuranceContext from './context/BuyingInsuranceContext';
import UserProfileContext from './context/UserProfileContext';
import InsurancePurchaseContext from './context/InsuracePurchaseContext';
import { lazyWithPreload } from './utilities/RLazyLoading';
import { Switch, Route, BrowserRouter as Router, Redirect,useLocation } from 'react-router-dom';
import { LoginProtectedRoute } from './components/Core/Routes/LoginProtectedRoute'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJSUtils from '@date-io/dayjs'
import PolicyManagementContext from './context/PolicyManagementContext';
import { BrokerProtectedRoute } from './components/Core/Routes/BrokerProtectedRoute';
import { FlowBrokerProtectedRoute } from './components/Core/Routes/FlowBrokerProtectedRoute';
import { SkywatchThemeSwitcher } from './theme/SkywatchThemeSwitcher';
import { changeFavicon, isUK } from './services/utils';
import { SessionService } from './services/SessionService';
import { TokenProtectedRoute } from './components/Core/Routes/TokenProtectedRoutes';
import { InsurerFooter } from './components/Footer/InsurerFooter';
import BrokerConfigService from './services/BrokerConfigService';
import { ServiceWorkerWrapper } from './serviceworker/ServiceWorkerWrapper';
import {Declination} from './components/Insurance/Declination'
import { EntranceProtectedRoute } from './components/Core/Routes/EntranceProtectedRoute';
import { UUD } from './components/Insurance/UUD';
const MonthlyInsurance = lazyWithPreload(() => import( /* webpackChunkName: "MonthlyInsurance" */ './components/Insurance/MonthlyInsurance').then(module => ({ default: module.MonthlyInsurance })));
const PolicyManagementLayout = lazyWithPreload(() => import( /* webpackChunkName: "PolicyManagementLayout" */'./components/PolicyManagement/PolicyManagementLayout').then(module => ({ default: module.PolicyManagementLayout })))
const SideMenu = lazyWithPreload(() => import(/* webpackChunkName: "SideMenu" */ './components/SideMenu/SideMenu'))
MonthlyInsurance.preload()
PolicyManagementLayout.preload()
SideMenu.preload()
const DiscountsLayout = lazyWithPreload(() => import(/* webpackChunkName: "DiscountsLayout" */ './components/Discounts/DiscountsLayout').then(module => ({default: module.DiscountsLayout})));
const BrokerRoutes = lazyWithPreload(() => import(/* webpackChunkName: "BrokerRoutes" */'./components/Broker/BrokerRoutes').then(module => ({default: module.BrokerRoutes })));
const Account = lazyWithPreload(() => import(/* webpackChunkName: "Account" */ './components/Account/Account').then(module => ({default: module.Account })));
const Login = lazyWithPreload(() => import(/* webpackChunkName: "Login" */'./components/Login/Login').then(module => ({ default: module.Login })));
const ForgotPassword = lazyWithPreload(() => import(/* webpackChunkName: "ForgotPassword" */'./components/Login/ForgotPassword').then(module => ({ default: module.ForgotPassword })));
const ServerResponsePopup = lazy(() => import(/* webpackChunkName: "ServerResponsePopup" */'./components/PopUps/ServerResponsePopup').then(module => ({default: module.ServerResponsePopup})));
const LoadingAnimation = lazy(() => import(/* webpackChunkName: "LoadingAnimation" */'./components/PopUps/LoadingAnimation'));
const ProposalRoutes = lazyWithPreload(() => import(/* webpackChunkName: "ProposalRoutes" */'./components/Proposal/ProposalRoutes').then(module => ({ default: module.ProposalRoutes })));
const ConfirmChangeEmail = lazy(() => import (/* webpackChunkName: "ConfirmChangeEmail" */'./components/Account/ConfirmChangeEmail').then(module => ({default: module.ConfirmChangeEmail})));
const ClaimRouter = lazyWithPreload(() => import (/* webpackChunkName: "ClaimRouter" */'./components/Claims/ClaimRouter').then(module => ({default: module.ClaimRouter})));

Account.preload()
Login.preload()
ProposalRoutes.preload()
DiscountsLayout.preload()
BrokerRoutes.preload()
ClaimRouter.preload()

Number.prototype.toLocaleStringToFixed = function() {
  return this.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
};

class App extends React.Component {

  async componentDidMount() {
    await FirebaseService.init()
    changeFavicon()
    if(navigator.vendor.match(/apple/i)) {
      // require('./styles/safarioverrides.scss') --- Did Safari fixed thier bugs ?? 
    }
  }

  getDefaultRoute() {
    if (SessionService.isLoggedIn()){
      if (SessionService.isBroker()){
        return "/broker/portal";
      }
    }
    else {
      if (!BrokerConfigService.isSkywatchBroker()){
        // return "/brokerLogin";
      }
    }
    return "/insurance/annual";
  }

  render () {
    return (
      <MuiPickersUtilsProvider utils={DayJSUtils}>
      <>
        <ServiceWorkerWrapper />
        <SkywatchThemeSwitcher>
        <Suspense fallback={<div />}>
          <Router>
            <SessionContext>
              <SideMenu />
              
              <Switch>
              <EntranceProtectedRoute path="/entrance" />

                <FlowBrokerProtectedRoute path="/insurance">
                  <BuyingInsuranceContext>
                    <InsurancePurchaseContext>
                        <MonthlyInsurance />
                    </InsurancePurchaseContext>
                  </BuyingInsuranceContext>
                </FlowBrokerProtectedRoute>

                <BrokerProtectedRoute path="/(broker|brokerPortal|brokerportal|brokerPanel|brokerpanel)" >
                  <BrokerRoutes />
                </BrokerProtectedRoute>

                <Route path={FirebaseService.getBoolean(FirebaseConfigKey.INSURED_LOGIN_AVAILABLE) ? "/(login|brokerLogin)" : "/brokerLogin"}>
                    <Login key={1} />
                </Route>

                <Route path="/forgot-password" component={ForgotPassword} />

                <Route path="/logout">
                    <Login key={3} />
                </Route>

                <Route path="/register"> 
                    <Login key={2} />
                </Route>

                <LoginProtectedRoute path="/policy-management">
                  <PolicyManagementContext>
                    <PolicyManagementLayout />
                  </PolicyManagementContext>
                </LoginProtectedRoute>

                <Route path='/discounts' component={DiscountsLayout} />

                <LoginProtectedRoute path="/account">
                  <UserProfileContext>
                      <Account />
                  </UserProfileContext>
                </LoginProtectedRoute>

                <TokenProtectedRoute path="/proposal" component={ProposalRoutes} />
                <TokenProtectedRoute path="/changeEmail" component={ConfirmChangeEmail} />

                <Route path="/claim" component={ClaimRouter} />
                <Route path="/declination" component={Declination}/>
                <Route path="/uud" component={UUD}/>

                <Route>
                    <Redirect to={{
                      pathname: this.getDefaultRoute(),
                      search: window.location.search,
                    }} />
                </Route>

              </Switch>

            </SessionContext> 
            
            <InsurerFooter />   
          </Router>
          </Suspense>

          <Suspense fallback={<div />}>
            <LoadingAnimation /> 
            <ServerResponsePopup />
          </Suspense>
        </SkywatchThemeSwitcher>
      </>
      </MuiPickersUtilsProvider>
    )
  }
}

export default App;
